import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTooltipComponent {
  @Input() isNotification: boolean = false;
  @Input() lgSize: boolean = false;
  @Input() editDate: boolean = false;
  @Input() text: string = '';
}
