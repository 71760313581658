import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-awesome-tooltip',
  templateUrl: './awesome-tooltip.component.html',
  styleUrls: ['./awesome-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AwesomeTooltipComponent {
  @Input() text = '';
}
